import { TypeAnimation } from "react-type-animation";
import { HomePanelBase } from "./HomePage";
import CoursableIcons from "../../../utils/CoursableIcons";
import CoursableLogo from "../../elements/CoursableLogo";
import MarkdownView from "../../elements/MarkdownView";
import { HomePageMaterialPreview } from "./Projects/InfoBlocks/SummariesGraphic";

const HomeProjectsPanel = () => {
  const aiResponse = `
Sure thing! Here's a summary of the paper you've uploaded:

#### 1. Introduction
The document is a review article titled "Apple phytochemicals and their health benefits" published in the Nutrition Journal. It discusses...

#### 2. Methods
The authors searched for articles on PubMed and Google Scholar using the keywords "apple", "phytochemicals", and "health benefits"...
`;

  return (
    <HomePanelBase title="Projects" description="Ask AI questions about your documents and videos. Generate flashcards, summaries, quizzes, and more." icon={CoursableIcons.Project()}>
      <div className="w-full flex flex-col gap-4 border border-systemGray-300 bg-systemGray-100 rounded-lg p-4 select-none pointer-events-none">
        <MessageBox from="user">Can you create a short summary of research paper?</MessageBox>
        <MessageBox from="ai">{aiResponse}</MessageBox>
        <div className="w-full text-mini md:text-sm bg-background py-2 px-3 rounded-lg flex items-center shadow">
          <TypeAnimation
            className="w-full line-clamp-1 text-foreground"
            repeat={Infinity}
            speed={60}
            deletionSpeed={80}
            sequence={["Can you now please convert it to bullet points?", 3000, "Can you extract the main thesis?", 3000, "Focus on the first page, please.", 3000, "What where the key points?", 3000]}
          />
          {CoursableIcons.Send("shrink-0 text-systemGray-400")}
        </div>
        <div className="w-full flex flex-wrap items-center justify-start md:justify-center gap-2 md:gap-2">
          <HomePageMaterialPreview className="shadow-none border-systemGray-300" name="Research paper" icon={CoursableIcons.PptxDoc("text-red-500")} />
          <HomePageMaterialPreview className="shadow-none border-systemGray-300" name="Last exam" icon={CoursableIcons.DocxDoc("text-blue-500")} />
          <HomePageMaterialPreview className="shadow-none border-systemGray-300" name="Exam prep recording" icon={CoursableIcons.YouTube("text-red-500")} />
        </div>
      </div>
    </HomePanelBase>
  );
};

export default HomeProjectsPanel;

const MessageBox = ({ from, children }: { from: "user" | "ai"; children: string }) => {
  return (
    <div className="w-full gap-2 flex items-start justify-start text-sm">
      {from === "user" ? (
        <div className="bg-gradient-to-t from-systemGray-400 to-systemGray-300 rounded-full w-[18px] md:w-6 h-[18px] md:h-6 flex items-center justify-center shrink-0 text-micro font-semibold" />
      ) : (
        <>
          <CoursableLogo className="md:hidden" size={5} />
          <CoursableLogo className="hidden md:block" size={6} />
        </>
      )}
      <div className="w-full flex flex-col items-start justify-start">
        <div className="font-semibold text-mini md:text-base text-foreground">{from == "user" ? "Me" : "Coursable Assistant"}</div>
        <MarkdownView className="text-mini md:text-sm leading-4 md:leading-5">{children}</MarkdownView>
      </div>
    </div>
  );
};
