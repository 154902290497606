import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TbNotebook as MaterialsIcon } from "react-icons/tb";
import CoursableIcons from "../../../../utils/CoursableIcons";
import { SmallWaves } from "../../../elements/VectorWaves/Waves";
import Button from "../../../elements/Button";

const CourseEditorSection = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center px-4 bg-gradient-to-b from-brand-50 to-background to-20%">
      <SmallWaves />
      <div className="w-full flex flex-col gap-8 items-center max-w-5xl py-20">
        <div className="flex gap-2 items-center justify-center text-2xl md:text-3xl">
          {CoursableIcons.Edit("text-brand-500 mt-1")}
          <h3 className="text-center font-semibold text-foreground">
            Powerful AI <span className="text-brand-500">Course Editor</span>
          </h3>
        </div>
        <p className="text-systemGray-500 max-w-2xl text-center">
          Beyond generating courses on virtually any subject from scratch, Coursable provides a powerful{" "}
          <span className="opacity-80 px-1 pb-[1px] font-semibold rounded-md text-brand-500 border bg-brand-50 border-brand-200">AI platform for creating, editing, and distributing</span> courses. Generate outlines, write content, find
          quality materials, and build quizzes faster than ever before.
        </p>
        <MediaSelector
          ctaLink="/app/courses"
          title="Generate"
          options={[
            {
              title: "Courses",
              icon: CoursableIcons.GraduationCap(),
              image: "/Images/Home/Courses/CourseScreenshot.png",
              alt: "Coursable Course Screenshot",
            },
            {
              title: "Content",
              icon: CoursableIcons.Text(),
              image: "/Images/Home/Courses/GenerateContentScreenshot.png",
              alt: "Coursable Generate Content Screenshot",
            },
            {
              title: "Materials",
              icon: <MaterialsIcon />,
              image: "/Images/Home/Courses/MaterialsScreenshot.png",
              alt: "Coursable Course Screenshot",
            },
            {
              title: "Quizzes",
              icon: CoursableIcons.Quizzes(),
              image: "/Images/Home/Courses/QuizScreenshot.png",
              alt: "Coursable Course Screenshot",
            },
          ]}
          tallestImg={1}
        />
      </div>
    </div>
  );
};

export default CourseEditorSection;

const StepDuration = 5000;

export const MediaSelector = ({ ctaLink, title, options, tallestImg }: { title: string; options: { title: string; icon: React.ReactNode; image: string; alt: string }[]; tallestImg: number; ctaLink: string }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const timeoutRef = useRef<NodeJS.Timeout>();

  function SelectStep(index: number, userClicked: boolean = false) {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setSelectedIndex(index);

    timeoutRef.current = setTimeout(() => {
      SelectStep((index + 1) % 3);
    }, StepDuration * (userClicked ? 2 : 1));
  }

  useEffect(() => {
    SelectStep(0);
  }, []);

  return (
    <div className="w-full max-w-5xl flex flex-col md:flex-row gap-4 md:gap-28 items-center justify-center">
      <div className="w-full md:w-1/2 pr-0 md:pr-10 flex flex-col gap-2 items-start justify-center">
        <div className="text-lg md:text-xl w-full text-center md:text-left font-semibold">{title}</div>
        {options.map((option, index) => (
          <StepButton title={option.title} icon={option.icon} index={index} selectedIndex={selectedIndex} SelectStep={() => SelectStep(index, true)} />
        ))}
        <Button to={ctaLink} className="mt-8 hidden md:inline-flex" size="lg">
          Start Now for Free
        </Button>
      </div>
      <div className="w-full md:w-1/2 relative pointer-events-none">
        <img className={`w-full border rounded-xl shadow-lg-c ${selectedIndex !== tallestImg && "opacity-0"} duration-300`} src={options[tallestImg].image} alt={options[tallestImg].alt} />
        {options.map((option, index) =>
          index === tallestImg ? null : <img className={`absolute top-1/2 -translate-y-1/2 border rounded-xl p-2 bg-white shadow-lg-c ${selectedIndex !== index && "opacity-0"} duration-300`} src={option.image} alt={option.alt} />
        )}
      </div>
      <Button to={ctaLink} className="mt-4 md:hidden" size="lg">
        Start Now for Free
      </Button>
    </div>
  );
};

const StepButton = ({ title, index, icon, selectedIndex, SelectStep }: { title: string; icon: React.ReactNode; index: number; selectedIndex: number; SelectStep: () => void }) => {
  const isSelected = index === selectedIndex;

  return (
    <button
      onClick={SelectStep}
      className={`flex items-center gap-2 justify-start rounded-md p-2 w-full text-left border ${
        isSelected ? "text-foreground font-semibold shadow-lg-c border-systemGray-200 border-l-4 border-l-brandBlue-400" : "text-systemGray-500 border-transparent hover:border-systemGray-200"
      } duration-300`}
    >
      {icon}
      <h5>{title}</h5>
    </button>
  );
};
