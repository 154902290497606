import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CoursableProject } from "../../../backend/Projects/types";
import CTAButton from "../../elements/CTAButton";
import CoursableIcons from "../../../utils/CoursableIcons";
import { useNotifications } from "../../../utils/NotificationsContext";
import LoadingIndicator from "../../elements/LoadingIndicator";
import Dropdown from "../../elements/DropdownMenu/Dropdown";
import { ContextMenuButton, ContextMenuDivider } from "../../elements/DropdownMenu/ContextMenu";
import { ContextMenuDeleteConfirmation } from "../../elements/DropdownMenu/ContextMenuConfirmation";
import { ProjectsContext } from "./ProjectsPage";
import { DeleteProject, RenameProject, SubscribeToAllProjectsChanges } from "../../../backend/Projects/ProjectBase";
import { Unsubscribe } from "firebase/firestore";
import { TypeAnimation } from "react-type-animation";

const ProjectsPageSidemenu = ({ collapsed }: { collapsed: boolean }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectsContext = useContext(ProjectsContext);

  const [projects, setProjects] = useState<CoursableProject[] | undefined>();
  const projectsListener = useRef<Unsubscribe | undefined>(undefined);

  function FetchAndSubscribeToProjects() {
    projectsListener.current?.();
    projectsListener.current = SubscribeToAllProjectsChanges((projects) => {
      setProjects(projects);
    });
  }

  useEffect(() => {
    FetchAndSubscribeToProjects();

    return () => {
      projectsListener.current?.();
    };
  }, []);

  return (
    <>
      <div onClick={() => navigate("")} className={`border-r flex flex-col overflow-y-auto overflow-x-clip duration-300 relative py-4 ${collapsed ? "px-0 opacity-0" : "px-4 opacity-100"}`}>
        <div className={`w-full flex text-2xl font-semibold`}>Projects</div>
        {projects && projects.length > 0 ? (
          <div className="flex flex-col gap-2 w-full py-4 overflow-y-auto overflow-x-clip grow">
            {projects.map((project) => (
              <SidemenuItem key={project.id} project={project} selectedID={id} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col w-full h-full grow items-center justify-center">
            <div className="text-systemGray-500 text-sm text-center line-clamp-4">
              You have no projects. Click on{" "}
              <button onClick={() => projectsContext.OpenNewProjectModal?.()} className="font-semibold text-brand-500 hover:text-brand-600 duration-100">
                New Project
              </button>{" "}
              below to get started.
            </div>
          </div>
        )}
        <CTAButton
          onClick={(e) => {
            e.stopPropagation();
            projectsContext.OpenNewProjectModal?.();
          }}
          className="!text-base mt-auto truncate"
        >
          New Project
        </CTAButton>
      </div>
    </>
  );
};

export default ProjectsPageSidemenu;

const SidemenuItem = ({ project, selectedID }: { project: CoursableProject; selectedID: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const [animatingName, setAnimatingName] = useState(false);
  const allowNameAnimation = useRef(false);

  const isSelected = project.id === selectedID;

  const [name, setName] = useState(project.name);
  const [renaming, setRenaming] = useState(false);

  const navigate = useNavigate();
  const { sendError } = useNotifications();

  const [confirmDeleting, setConfirmDeleting] = useState(false);

  async function TryDeleteProject() {
    if (project.isGenerating || project.isGeneratingSummary || project.isGeneratingFlashcards || project.isGeneratingQuiz) return sendError("Cannot delete a project when something is generating.");

    setLoading(true);
    try {
      navigate("");
      await DeleteProject(project.id);
    } catch (error) {
      setLoading(false);
      sendError();
    }
  }

  async function SaveName() {
    allowNameAnimation.current = false;
    setTimeout(() => {
      allowNameAnimation.current = true;
    }, 1000);

    setRenaming(false);
    if (name.length === 0) return setName(project.name);

    try {
      await RenameProject(project.id, name);
    } catch (error) {
      console.error(error);
      sendError("Oops, failed renaming this chat. Please try again.");
    }
  }

  function HandleInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      SaveName();
    }
  }

  useEffect(() => {
    setName(project.name);
  }, [project]);

  useEffect(() => {
    if (allowNameAnimation.current) setAnimatingName(true);

    setTimeout(() => {
      allowNameAnimation.current = true;
    }, 1000);
  }, [project.name]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (loading) return;
        navigate(`${project.id}`);
      }}
      className={`cursor-pointer group w-full flex items-center justify-between text-left p-2 rounded-lg border ${
        isSelected ? "bg-brand-25 dark:bg-brand-100 border-brand-500 shadow" : "border-transparent hover:bg-systemGray-100"
      } duration-100 ${loading ? "opacity-50 pointer-events-none" : "opacity-100"}`}
    >
      {renaming ? (
        <input autoFocus onKeyDown={HandleInput} onBlur={SaveName} type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} className="w-full focus:outline-none bg-brand-100 dark:bg-brand-200 rounded-md px-1" />
      ) : (
        <div className="truncate">{animatingName ? <TypeAnimation cursor={false} sequence={[name, 100, () => setAnimatingName(false)]} /> : name}</div>
      )}
      {loading && <LoadingIndicator className="ml-auto mr-2 stroke-systemGray-400 shrink-0" />}
      {!renaming && (
        <div className="relative">
          <Dropdown size="icon" className={`${isSelected ? "opacity-100" : "opacity-0"} group-hover:opacity-100 duration-100 h-full`} containerClassName="h-6">
            <ContextMenuButton label="Rename" icon={CoursableIcons.Edit()} onClick={() => setRenaming(true)} />
            <ContextMenuDivider />
            <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setConfirmDeleting(true)} destructive />
          </Dropdown>
          <ContextMenuDeleteConfirmation isOpen={confirmDeleting} setIsOpen={setConfirmDeleting} onDelete={TryDeleteProject} />
        </div>
      )}
    </div>
  );
};
