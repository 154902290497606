import { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { ProjectFile, CoursableProject, Flashcard, ProjectFlashcardSet, FlashcardDifficulty } from "../../../../../backend/Projects/types";
import { GenerationBlock, useGenerationBlock } from "../GenerationBlock";
import { DoneButton, formattedDateGenerated, ShareProjectDerivative, useWorkspaceStates } from "../Shared";
import useDidScroll from "../../../../../utils/Hooks/useDidScroll";
import Dropdown from "../../../../elements/DropdownMenu/Dropdown";
import { ContextMenu, ContextMenuButton, ContextMenuDivider, ContextMenuLoadingLabel } from "../../../../elements/DropdownMenu/ContextMenu";
import CoursableIcons from "../../../../../utils/CoursableIcons";
import { ContextMenuDeleteConfirmation } from "../../../../elements/DropdownMenu/ContextMenuConfirmation";
import LoadingIndicator from "../../../../elements/LoadingIndicator";
import { useNotifications } from "../../../../../utils/NotificationsContext";
import ProjectsFlashcardsPractice from "./ProjectsFlashcardsPractice";
import Button from "../../../../elements/Button";
import InputField from "../../../../elements/InputField";
import InputBox from "../../../../elements/InputBox";
import { cn } from "../../../../../utils/UtilityMethods";
import Badge from "../../../../elements/Badge";
import "../../../../../utils/StringExtensions";
import DifficultySelector from "./DifficultySelector";
import { useProjectManager } from "../../Hooks/useProjectManager";

const ProjectFlashcards = () => {
  const { project, Flashcards, Files } = useProjectManager();
  const { prompt, setPrompt, selectedFiles, setSelectedFiles } = useGenerationBlock(Files.files);
  const [selectedDifficulties, setSelectedDifficulties] = useState<FlashcardDifficulty[]>(["easy", "medium", "hard"]);

  const [selectedFlashcardSetIndex, setSelectedFlashcardSetIndex] = useState<number | null>(Flashcards.flashcards.length > 0 ? 0 : null);
  const selectedFlashcardSet = selectedFlashcardSetIndex !== null ? Flashcards.flashcards[selectedFlashcardSetIndex] : null;
  const [editingFlashcardSet, setEditingFlashcardSet] = useState<ProjectFlashcardSet | null>(null);

  const [flashcardSetName, setFlashcardSetName] = useState<string | null>(selectedFlashcardSet?.name ?? null);
  const dateGenerated = useMemo(() => {
    if (!selectedFlashcardSet) return "";
    return formattedDateGenerated(selectedFlashcardSet.dateCreated);
  }, [selectedFlashcardSet]);

  const { renaming, setRenaming, editing, setEditing, saving, setSaving, deleting, setDeleting } = useWorkspaceStates();

  const [shownFlashcardSetNameIndex, setShownFlashcardSetNameIndex] = useState<number>(selectedFlashcardSet?.name.length ?? 0);

  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState<boolean>(false);
  const [revealAllFlashcards, setRevealAllFlashcards] = useState<boolean | null>(null);
  const [practice, setPractice] = useState<boolean>(false);
  const { ShareDropdown, SharePopup } = ShareProjectDerivative({
    type: "flashcards",
    derivative: selectedFlashcardSet ?? undefined,
    projectID: project?.id,
    setPublicID: (publicID) => (selectedFlashcardSet ? Flashcards.SetPublicID(selectedFlashcardSet.id, publicID) : undefined),
  });

  const { scrollRef, didScroll } = useDidScroll<HTMLDivElement>();
  const { sendError } = useNotifications();

  async function ClickGenerateFlashcards() {
    if (!project) return;

    try {
      const flashcardSet = await Flashcards.Generate(selectedFiles, prompt, selectedDifficulties, selectedFlashcardSet?.id);
      setFlashcardSetName(flashcardSet.name);
      setSelectedFlashcardSetIndex(0);
      StartTypingSummaryName(flashcardSet.name.length);
    } catch (error) {
      sendError("Flashcard generation failed.", "Could not generate flashcards from your materials, please try again.");
      Flashcards.SetIsGenerating(false);
    }
  }
  async function ClickSaveFlashcardSetName() {
    if (!selectedFlashcardSet || !project) return;

    setSaving(true);
    try {
      await Flashcards.SaveName(selectedFlashcardSet.id, flashcardSetName ?? "");
      setRenaming(false);
    } catch (error) {
      sendError();
    }
    setSaving(false);
  }
  async function StartEditingFlashcardSet() {
    if (!selectedFlashcardSet) return;
    setEditingFlashcardSet(selectedFlashcardSet);
    setEditing(true);
  }
  async function ClickSaveFlashcardSet() {
    if (!selectedFlashcardSet || !project || !editingFlashcardSet) return;

    setSaving(true);
    try {
      await Flashcards.SaveSet(editingFlashcardSet);
      setEditingFlashcardSet(null);
      setEditing(false);
    } catch (error) {
      sendError();
    }
    setSaving(false);
  }
  async function ClickDeleteFlashcardSet() {
    if (!selectedFlashcardSet || !project) return;

    setDeleting(true);
    try {
      await Flashcards.Delete(selectedFlashcardSet.id);
      setSelectedFlashcardSetIndex(null);
    } catch (error) {
      sendError();
    }
    setDeleting(false);
  }

  function StartTypingSummaryName(length: number, index: number = 0) {
    if (index !== 0 && index > length) return;
    setShownFlashcardSetNameIndex(index);

    setTimeout(() => {
      StartTypingSummaryName(length, index + 1);
    }, 50);
  }

  function ToggleRevealAll(isOn: boolean) {
    setRevealAllFlashcards(isOn);
    setTimeout(() => {
      setRevealAllFlashcards(null);
    }, 300);
  }

  useEffect(() => {
    setSelectedFlashcardSetIndex(Flashcards.flashcards.length > 0 ? 0 : null);
  }, [Flashcards.flashcards]);

  useEffect(() => {
    setFlashcardSetName(selectedFlashcardSet?.name ?? null);
  }, [selectedFlashcardSet]);

  return (
    <div className={`w-full flex flex-col items-start justify-start gap-4 pt-6 overflow-auto h-full`}>
      <div className="w-full text-sm text-systemGray-500">Generate informative flashcards to test you knowledge and study more efficiently.</div>
      <GenerationBlock
        prompt={prompt}
        generating={project?.isGeneratingFlashcards ?? false}
        empty={!selectedFlashcardSet}
        files={Files.files}
        selectedFiles={selectedFiles}
        setPrompt={setPrompt}
        setSelectedFiles={setSelectedFiles}
        ClickGenerate={ClickGenerateFlashcards}
        actions={<DifficultySelector selectedDifficulties={selectedDifficulties} setSelectedDifficulties={setSelectedDifficulties} generating={project?.isGeneratingFlashcards ?? false} />}
        promptPlaceholder="Ask for anything specific for your flashcards"
      />
      <div className="border-b mt-4 w-full" />
      {selectedFlashcardSet ? (
        <>
          <div className={`w-full flex justify-between items-center gap-4 overflow-x-clip shrink-0 z-1 ${saving && "pointer-events-none opacity-70"} @container`}>
            {renaming ? (
              <>
                <InputField className="w-full" value={flashcardSetName ?? ""} onValueChange={setFlashcardSetName} />
                <DoneButton onClick={ClickSaveFlashcardSetName} saving={saving} />
              </>
            ) : (
              <>
                <div className="w-full flex flex-col items-start justify-start relative my-2">
                  {selectedFlashcardSet.publicID && <div className="absolute bottom-full left-0 text-xs text-brandBlue-300 flex items-center justify-start gap-1">{CoursableIcons.PersonsFill("text-base -mt-0.5")} Shared</div>}
                  <div className="text-brand-500 w-full text-sm md:text-base">{flashcardSetName?.slice(0, shownFlashcardSetNameIndex)}</div>
                  <div className="text-systemGray-400 text-xs font-light">
                    {selectedFlashcardSet.flashcards.length} flashcards | {dateGenerated}
                  </div>
                </div>
                {editing ? (
                  <DoneButton onClick={ClickSaveFlashcardSet} saving={saving} />
                ) : (
                  <div className="flex flex-col items-end @md:flex-row gap-2">
                    <Button variant="outline" onClick={() => setPractice(true)}>
                      Practice{CoursableIcons.Flashcard()}
                    </Button>
                    <div className="flex items-center justify-end gap-2">
                      {ShareDropdown}
                      <div className="relative">
                        <Dropdown
                          label={
                            <>
                              <span className="hidden @md:inline">More</span>
                              {CoursableIcons.Ellipsis()}
                            </>
                          }
                          chevron={false}
                          className="@md:w-auto h-9 w-9"
                        >
                          <ContextMenuButton label="Reveal all" icon={CoursableIcons.Eye()} onClick={() => ToggleRevealAll(true)} />
                          <ContextMenuButton label="Hide all" icon={CoursableIcons.EyeSlash()} onClick={() => ToggleRevealAll(false)} />
                          <ContextMenuDivider />
                          <ContextMenuButton label="Rename" icon={CoursableIcons.Edit()} onClick={() => setRenaming(true)} />
                          <ContextMenuButton label="Edit" icon={CoursableIcons.Edit()} onClick={StartEditingFlashcardSet} />
                          <ContextMenuButton
                            label="Export as PDF"
                            icon={CoursableIcons.Share()}
                            onClick={() => {
                              window.open(`/app/projects/${project?.id}/flashcards/${selectedFlashcardSet.id}/pdf`, "_blank");
                            }}
                          />
                          <ContextMenuDivider />
                          <ContextMenuButton label="Delete" icon={CoursableIcons.Delete()} onClick={() => setShowingDeleteConfirmation(true)} destructive />
                        </Dropdown>
                        <ContextMenuDeleteConfirmation label="Are you sure you want to delete these flashcards?" width={200} isOpen={showingDeleteConfirmation} setIsOpen={setShowingDeleteConfirmation} onDelete={ClickDeleteFlashcardSet} />
                        <ContextMenu autoClose={false} isOpen={deleting} setIsOpen={setDeleting}>
                          <ContextMenuLoadingLabel label="Deleting" />
                        </ContextMenu>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={scrollRef} className={cn("w-full h-full px-4 pb-16 overflow-auto", didScroll && "border-t")}>
            <FlashcardSetView flashcardSet={selectedFlashcardSet} editing={editing} editingFlashcardSet={editingFlashcardSet} setEditingFlashcardSet={setEditingFlashcardSet} revealAll={revealAllFlashcards} />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-start w-full gap-1 opacity-60">
          {!!project?.isGeneratingFlashcards ? (
            <>
              <span className="text-lg">🔍</span> Generating flashcards <LoadingIndicator className="inline h-4 stroke-background mt-2 -ml-1 fill-systemGray-800" type="dots" />
            </>
          ) : (
            <>
              <span className="text-lg">📖</span> Your generated flashcards will appear here
            </>
          )}
        </div>
      )}
      {selectedFlashcardSet && <ProjectsFlashcardsPractice isOpen={practice} setIsOpen={setPractice} flashcardSet={selectedFlashcardSet} />}
      {SharePopup}
    </div>
  );
};

export default ProjectFlashcards;

interface FlashcardSetView {
  flashcardSet: ProjectFlashcardSet;
  revealAll: boolean | null;
  editing?: boolean;
  editingFlashcardSet?: ProjectFlashcardSet | null;
  setEditingFlashcardSet?: (f: ProjectFlashcardSet | null) => void;
}

export const FlashcardSetView = ({ flashcardSet, editing, editingFlashcardSet, setEditingFlashcardSet, revealAll }: FlashcardSetView) => {
  function AddFlashcard() {
    if (!editingFlashcardSet || setEditingFlashcardSet === undefined) return;
    const newFlashcard: Flashcard = { question: "", answer: "", difficulty: "easy" };
    setEditingFlashcardSet({ ...editingFlashcardSet, flashcards: [...editingFlashcardSet.flashcards, newFlashcard] });
  }

  function EditFlashcard(atIndex: number, updatedFlashcard: Flashcard) {
    if (!editingFlashcardSet || setEditingFlashcardSet === undefined) return;
    const newFlashcards = [...editingFlashcardSet.flashcards];
    newFlashcards[atIndex] = updatedFlashcard;
    setEditingFlashcardSet({ ...editingFlashcardSet, flashcards: newFlashcards });
  }

  function DeleteFlashcard(atIndex: number) {
    if (!editingFlashcardSet || setEditingFlashcardSet === undefined) return;
    const newFlashcards = [...editingFlashcardSet.flashcards];
    newFlashcards.splice(atIndex, 1);
    setEditingFlashcardSet({ ...editingFlashcardSet, flashcards: newFlashcards });
  }

  return (
    <div className={cn(`w-full flex flex-col items-start gap-4`)}>
      <div
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        }}
        className="w-full grid gap-4 @container"
      >
        {(editingFlashcardSet ?? flashcardSet).flashcards.map((flashcard, index) => {
          return (
            <FlashcardView
              key={index}
              flashcard={flashcard}
              index={index}
              editingFlashcard={editingFlashcardSet ? flashcard : null}
              revealAll={revealAll}
              canDelete={editingFlashcardSet ? editingFlashcardSet?.flashcards.length > 1 : false}
              EditFlashcard={(updatedCard) => EditFlashcard(index, updatedCard)}
              DeleteFlashcard={() => DeleteFlashcard(index)}
            />
          );
        })}
        {editing && <NewFlashcardView AddFlashcard={AddFlashcard} />}
      </div>
    </div>
  );
};

interface FlashcardViewProps {
  flashcard: Flashcard;
  index?: number;
  editingFlashcard: Flashcard | null;
  revealAll: boolean | null;
  canDelete: boolean;
  EditFlashcard: (c: Flashcard) => void;
  DeleteFlashcard: () => void;
}
const FlashcardView = ({ flashcard, index, editingFlashcard, revealAll, canDelete, EditFlashcard, DeleteFlashcard }: FlashcardViewProps) => {
  const projectManager = useProjectManager();
  const [reveal, setReveal] = useState<boolean>(false);

  const answerRef = useRef<HTMLDivElement>(null);

  const [translateY, setTranslateY] = useState<number>(0);
  const [anim, setAnim] = useState<boolean>(false);
  const [openDifficultyEdit, setOpenDifficultyEdit] = useState<boolean>(false);

  const editing = !!editingFlashcard;
  const showAnswer = reveal || editing;

  const hasQuestion = flashcard.question.length > 0;
  const hasAnswer = flashcard.answer.length > 0;

  function Toggle() {
    if (editing) return;
    setReveal(!reveal);
  }

  function CalculateSize() {
    const answerHeight = answerRef.current?.clientHeight ?? 0;
    setTranslateY((answerHeight + 32) / 2);
  }

  function EditQuestion(question: string) {
    EditFlashcard({ ...flashcard, question });
  }

  function EditAnswer(answer: string) {
    EditFlashcard({ ...flashcard, answer });
  }

  useEffect(() => {
    setOpenDifficultyEdit(false);
  }, [editing]);

  useEffect(() => {
    if (revealAll === null) return;
    setReveal(revealAll);
  }, [revealAll]);

  useEffect(() => {
    if (!answerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      CalculateSize();
    });
    resizeObserver.observe(answerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      onClick={Toggle}
      onMouseEnter={() => setAnim(true)}
      onAnimationEnd={() => setAnim(false)}
      className={`${editing ? "p-4" : "p-4 @md:p-10"} flex-centered rounded-2xl hover:shadow-lg-c border-2 hover:border-brand-500 cursor-pointer duration-300 group relative ${
        anim && !editing && "animate-cornerShake"
      } bg-systemGray-100 hover:bg-background`}
    >
      <div className={`w-full flex-centered flex-col gap-8 group`}>
        <div
          style={{
            transform: `translateY(${showAnswer ? "0" : `calc(${translateY}px)`}) scaleX(${reveal && !editing ? "0.8" : "1"}) scaleY(${reveal && !editing ? "0.8" : "1"})`,
          }}
          className={`w-full flex-centered flex-col gap-2 ease-out-back text-center duration-300 ${reveal && !editing ? "opacity-50" : "opacity-100 scale-50"}`}
        >
          <div className="text-systemGray-400 text-mini select-none font-light">QUESTION</div>
          {editing && !!editingFlashcard ? (
            <InputBox containerClassName="w-full" className="w-full h-20" value={editingFlashcard.question} onValueChange={EditQuestion} placeholder="Enter your question" />
          ) : (
            <h5 className={`cursor-text ${!hasQuestion && "text-systemGray-500"} text-sm @md:text-base`}>{hasQuestion ? flashcard.question.replaceAll(/(【.*?】)/g, "") : "Blank question"}</h5>
          )}
        </div>
        <div ref={answerRef} className={`${showAnswer ? "opacity-100" : "opacity-0 pointer-events-none"} w-full flex-col flex-centered gap-2 duration-300 text-center`}>
          <div className="text-systemGray-400 text-mini select-none font-light">ANSWER</div>
          {editing && !!editingFlashcard ? (
            <InputBox containerClassName="w-full" className="w-full h-20" value={editingFlashcard.answer} onValueChange={EditAnswer} placeholder="Enter your answer" />
          ) : (
            <h5 className={`cursor-text ${!hasQuestion && "text-systemGray-500"} text-sm @md:text-base`}>{hasAnswer ? flashcard.answer.replaceAll(/(【.*?】)/g, "") : "Blank answer"}</h5>
          )}
        </div>
      </div>
      {editing ? (
        <button onClick={DeleteFlashcard} className={`text-systemGray-500 hover:text-red-500 active:text-red-400 duration-100 absolute top-4 right-4 ${!canDelete && "hidden"}`}>
          {CoursableIcons.Delete()}
        </button>
      ) : (
        <div className={`${showAnswer ? "text-systemGray-300" : "text-systemGray-400"} absolute bottom-2 left-0 right-0 flex-centered gap-1 opacity-0 group-hover:opacity-100 duration-300 text-mini`}>
          Click{" "}
          <div className="text-base relative">
            {CoursableIcons.EyeSlash(`${showAnswer ? "opacity-100" : "opacity-0"} duration-100`)}
            {CoursableIcons.Eye(`${showAnswer ? "opacity-0" : "opacity-100"} absolute inset-0 duration-100`)}
          </div>
        </div>
      )}
      {flashcard.difficulty && (
        <DifficultyBadge onClick={() => setOpenDifficultyEdit(true)} difficulty={flashcard.difficulty} className={"absolute top-3 left-3"}>
          {editing && (
            <ContextMenu align="start" isOpen={openDifficultyEdit} setIsOpen={setOpenDifficultyEdit}>
              <ContextMenuButton label="Easy" onClick={() => EditFlashcard({ ...flashcard, difficulty: "easy" })} />
              <ContextMenuButton label="Medium" onClick={() => EditFlashcard({ ...flashcard, difficulty: "medium" })} />
              <ContextMenuButton label="Hard" onClick={() => EditFlashcard({ ...flashcard, difficulty: "hard" })} />
            </ContextMenu>
          )}
        </DifficultyBadge>
      )}
      {!editing && projectManager !== null && (
        <Dropdown label={CoursableIcons.Ellipsis("horizontal", "text-lg")} size="sm" chevron={false} variant="ghost" containerClassName="absolute top-2 right-2">
          <ContextMenuButton label="Explain" icon={CoursableIcons.SparklesFill("text-brand-500")} onClick={() => projectManager.Messages.SendMessage("Please briefly explain this flashcard.", [{ type: "Flashcard", content: flashcard }])} />
          <ContextMenuDivider />
          <ContextMenuButton label="Attach to message" icon={CoursableIcons.Attachment()} onClick={() => projectManager.ChatInput.AddAttachment({ type: "Flashcard", content: flashcard })} />
        </Dropdown>
      )}
      {index !== undefined && !editing && (
        <Badge className="absolute bottom-3 right-3" variant="outline">
          {index + 1}
        </Badge>
      )}
    </div>
  );
};

const NewFlashcardView = ({ AddFlashcard }: { AddFlashcard: () => void }) => {
  return (
    <button onClick={AddFlashcard} className={`p-4 @md:p-10 flex-centered gap-2 rounded-2xl hover:shadow-lg-c border-2 hover:border-brand-500 cursor-pointer duration-300 group relative  bg-systemGray-100 hover:bg-background`}>
      {CoursableIcons.Plus()} Add Flashcard
    </button>
  );
};

interface DifficultyBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  difficulty: FlashcardDifficulty | "any";
  short?: boolean;
}

export const DifficultyBadge = ({ difficulty, short, children, className, ...props }: DifficultyBadgeProps) => {
  if (!difficulty) return null;
  return (
    <Badge {...props} className={className} variant={difficulty === "easy" ? "green" : difficulty === "medium" ? "yellow" : difficulty === "hard" ? "inverse" : "blue"}>
      {short ? (difficulty === "easy" ? "ES" : difficulty === "medium" ? "MD" : difficulty === "hard" ? "HD" : "ANY") : difficulty.capitalize()}
      {children}
    </Badge>
  );
};
