import { Navigate, useSearchParams } from "react-router-dom";
import CoursableIcons from "../../../utils/CoursableIcons";
import HomePageTemplate from "../../page-elements/HomePageTemplate";
import HomeProjectsPanel from "./HomeProjectsPanel";
import HomeCoursesPanel from "./HomeCoursesPanel";
import { useCallback, useEffect, useState } from "react";
import { LargeWaves, SmallWaves } from "../../elements/VectorWaves/Waves";
import { Link } from "react-router-dom";
import HeroGraphic from "./HeroGraphic";
import { HashLink } from "react-router-hash-link";
import CTAButton from "../../elements/CTAButton";
import Button from "../../elements/Button";
import { useAuth } from "../../../firebase/AuthContext";
import cookies from "../../../utils/CookiesManager";
import { pageMetatags } from "../../../utils/MetatagsGenerator";
import FeaturesBlock from "./HomePageFeaturesBlock";

const HomePage = () => {
  const { currentUser } = useAuth();
  const [queryParams] = useSearchParams();

  const appRedirectParam = queryParams.get("appRedirect");
  if (appRedirectParam === "false") cookies.set("appRedirect", false);

  const appRedirectCookie = cookies.get("appRedirect");
  if (currentUser && appRedirectCookie !== "false") return <Navigate to="/app" />;

  return (
    <HomePageTemplate helmet={pageMetatags({ canonical: "/" })}>
      <div className="flex flex-col items-center w-full overflow-x-clip">
        <Hero />
        <ProjectsCoursesPanel />
        <Spacer />
        <FeaturesBlock />
      </div>
    </HomePageTemplate>
  );
};

export default HomePage;

const Hero = () => {
  const today = new Date();

  const [sunCoords, setSunCoords] = useState({ x: 0, y: 0 });

  function calculateSunCoords(step: number) {
    const raduis = 30;

    const x = Math.sin(Math.PI * step) * raduis + 20;
    const y = Math.cos(Math.PI * step) * raduis + 28;

    setSunCoords({ x, y });
  }

  useEffect(() => {
    const hours = today.getHours();
    const minutes = today.getMinutes();
    let decimalTime = (hours % 12) + minutes / 60;

    let step: number;
    if (hours >= 12) {
      step = 0.9 + decimalTime * (0.7 / 12);
    } else {
      step = 1.6 - decimalTime * (0.7 / 12);
    }
    calculateSunCoords(step);
  }, []);

  const MakeLink = useCallback((to: string, text: string) => {
    return (
      <Link className={`text-brand-500 underline underline-offset-[6px] decoration-brand-100 hover:decoration-brand-500 duration-300 inline-block hover:shadow-md active:shadow-none`} to={to}>
        {text}
      </Link>
    );
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center bg-gradient-to-t from-brand-50 to-60% to-background">
      <div className="flex flex-col md:flex-row gap-20 md:gap-10 items-center justify-start w-full max-w-7xl py-20 relative px-4 md:px-12">
        <div className="flex flex-col items-center md:items-start text-center md:text-left gap-10 w-full md:w-1/2 relative z-[1]">
          {CoursableIcons.GraduationCapFill("text-6xl text-brand-500")}
          <div className="relative">
            <h1 className="text-4xl md:text-5xl font-bold text-foreground md:leading-tight">
              <span className="whitespace-nowrap">
                Smart{" "}
                <span
                  style={{
                    textShadow: "0px 5px 12px rgba(209, 36, 36, 0.2)",
                  }}
                  className="text-brand-500"
                >
                  Tools
                </span>{" "}
                for
              </span>
              <br />
              <span className="whitespace-nowrap">
                Modern{" "}
                <span
                  style={{
                    textShadow: "0px 5px 12px rgba(209, 36, 36, 0.2)",
                  }}
                  className="text-brand-500"
                >
                  Students
                </span>
              </span>
            </h1>
            {CoursableIcons.SparklesFill("absolute -top-10 -right-8 md:-right-16 text-brand-100 text-4xl md:text-5xl")}
          </div>
          <h3 className="text-base md:text-lg text-systemGray-500 max-w-md">
            Power up your study materials with AI {MakeLink("projects", "flashcards")}, {MakeLink("projects", "summaries")}, and {MakeLink("projects", "quizzes")}; pull insights and find answers faster with{" "}
            {MakeLink("projects", "documents chat")}.
          </h3>
          <h3 className="text-base md:text-lg text-systemGray-500 max-w-md -mt-8">
            Learn something new with personalized AI {MakeLink("courses", "courses")} and approved {MakeLink("courses", "study materials")} from the web.
          </h3>
          <CTAButton to="/signup" className="text-base md:text-lg">
            Get Started
          </CTAButton>
        </div>
        <HeroGraphic />
        <div
          style={{
            background: `radial-gradient(circle, rgba(209,36,36,1) 40%, rgba(209,36,36,0.5) 100%)`,
            top: `${sunCoords.y}rem`,
            right: `${sunCoords.x}rem`,
          }}
          className="hidden md:flex flex-col items-center justify-center rounded-full w-[12rem] h-[12rem] absolute ease-linear shadow-lg-c shadow-brand-400 z-0 text-brand-50 font-semibold text-2xl text-center select-none"
        />
      </div>
      <div className="w-full flex-centered px-4">
        <HashLink smooth to={"/#explore"} className="flex-centered gap-1 text-systemGray-600 hover:text-foreground active:text-systemGray-800 duration-300 group">
          {CoursableIcons.Chevron("down", "text-brand-200 mx-1 animate-bounce mt-[5px]")}
          <p className="text-mini md:text-base text-center">
            Explore what you can do with <span className="font-semibold text-brand-400 group-hover:text-brand-500 duration-300">Coursable</span>
          </p>
          {CoursableIcons.Chevron("down", "text-brand-200 mx-1 animate-bounce mt-[5px]")}
        </HashLink>
      </div>
      <LargeWaves className="z-[1]" />
    </div>
  );
};

const ProjectsCoursesPanel = () => {
  return (
    <div id="explore" className="w-full flex flex-col items-center justify-center bg-brand-700 dark:bg-brand-300 scroll-m-40">
      <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-10 py-10 pb-14 px-4">
        <HomeProjectsPanel />
        <HomeCoursesPanel />
      </div>
    </div>
  );
};

const Spacer = () => {
  return (
    <div className="w-full bg-gradient-to-b from-brand-50 to-background pb-24">
      <SmallWaves />
    </div>
  );
};

export const HomePanelBase = ({ title, description, icon, children }: { title: string; description: string; icon: React.ReactNode; children: React.ReactNode }) => {
  return (
    <div className="w-full flex flex-col items-center justify-start gap-4 rounded-2xl bg-background/90 dark:bg-background/80 shadow-lg hover:shadow-2xl hover:border-background border-transparent border-2 duration-300 p-4 md:p-8 h-full">
      <div className="text-brand-500 text-3xl md:text-4xl">{icon}</div>
      <h3 className="font-semibold text-2xl md:text-3xl text-foreground">{title}</h3>
      <h5 className="text-systemGray-700 w-full my-2 text-center max-w-md text-sm md:text-base">{description}</h5>
      {children}
      <div className="grow" />
      <Button size="lg" className="text-base shrink-0" to={title.toLowerCase()}>
        Explore {title}
      </Button>
    </div>
  );
};
